<template>
  <div>
    <div class='btn'>
      <el-button type='primary'
                 @click='add'>上传答题卡</el-button>
    </div>
    <uploadDialog ref="dialogRef" />

    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import { getSheetList, uploadSheetSchedule } from '@/api/sheet.js'
import { uploadSheetFile } from '@/api/upload.js'
import { loadings } from '@/utils/http.js'

import uploadDialog from './uploadDialog.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'sheetList',
      loadings,
      tableColumns: [
        { prop: 'test_sheet_id', align: 'center', label: '考试学生答题ID', },
        // { prop: 'url', align: 'center', label: '图片1路径' },
        // { prop: 'url2', align: 'center', label: '图片2路径' },
        { prop: 'create_time', align: 'center', label: '上传时间', },
        { prop: 'status', align: 'center', label: '审核状态', isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'text', btns: [
            { name: '', class: 'text_btn_primary', event: 'toInfo', isChangeText: true },
          ]
        },
      ],
      startLoading: 0,
      sitv: undefined//是否加载中定时器
    }
  },
  components: { uploadDialog },
  created () {
  },
  watch: {
    // loadings: {
    //   handler () {
    //     console.log("loading")
    //     if (loadings.visible) {
    //       console.log("loading")
    //       // loadings.close()
    //       loadings.visible = false
    //     }
    //   },
    // deep: true
    // }
  },
  mounted () {

    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      return value ? 'pass' : 'notpass'
    },

    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '审核成功' : '未审核'
      }
    },
    getText (prop, value, row) {
      if (prop == 'd') {
        return row.status ? '详情' : '审核'
      }
    },
    async initData (page, limit) {
      //   if(window.localStorage.getItem("sheet_paper_id")){
      //   this.$route.query.id=window.localStorage.getItem("sheet_paper_id")
      //   window.localStorage.removeItem("sheet_paper_id")
      // }
      let params = {
        page, limit,
        paper_test_id: this.$route.query.id,
      }
      const { data } = await getSheetList(params)
      this.tableData = data.list
      this.totalElements = data.count
    },
    toInfo (row) {
      let cardUrlObj = {
        url: row.url,
        url2: row.url2
      }
      window.localStorage.setItem("sheet_paper_id", this.$route.query.id)
      window.localStorage.setItem("cardUrlObj", JSON.stringify(cardUrlObj))
      this.$router.push('/exaManage/reviewSheet?sheet_id=' + row.test_sheet_id + "&paper_id=" + this.$route.query.id + '&status=' + row.status)

    },

    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },


    //显示上传弹窗
    add () {
      this.$refs.dialogRef.dialogVisible = true
    },
    uploadProgress (progressEvent) {
      if (progressEvent.lengthComputable) {   //是否存在进度
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.startLoading = percentCompleted//标记进度条记时开始
        this.$refs.dialogRef.disabled = true
      }
    },
    //弹窗回调上传
    async uploadSubmit (type) {
      if (this.sitv) { clearInterval(this.sitv) }

      try {
        // loadings.close()

        let count1 = 0, count2 = 0
        let randomProgress = Math.random() + ''
        this.sitv = setInterval(async () => {
          if (this.startLoading > 1 && count1 == count2) {
            count1 += 1
            let { data } = await uploadSheetSchedule({
              "progress": randomProgress,
              "test_paper_id": Number(this.$route.query.id)
            })
            count2 += 1

            // console.log(data)
            this.$refs.dialogRef.percentage = Math.round(data.schedule)
          }
          // else {
          //   count1 -= 1
          // }
        }, 1500);
        // this.$refs.dialogRef.disabled = true
        const data = await uploadSheetFile("filenames", { "test_paper_id": this.$route.query.id, "type": type, 'progress': randomProgress }, 20, "image/*", this.uploadProgress)
        clearInterval(this.sitv)
        this.$refs.dialogRef.disabled = false
        this.startLoading = 0
        console.log("完成")
        // await this.apiRunning(type)
        // this.isloading=false
        // return
        this.$refs.dialogRef.percentage = 100
        this.$refs.dialogRef.status1 = 'success'
        // this.$refs.dialogRef.showProgress = false
        this.$refs.dialogRef.handleClose()
        this.$message.success("上传成功")
        this.refresh()
      } catch (err) {
        console.log(err, err)
        clearInterval(this.sitv)
        this.startLoading = 0
        loadings.close()
        this.$refs.dialogRef.percentage = 100
        this.$refs.dialogRef.status1 = 'exception'
        this.$message.error("请重新上传")
        this.$refs.dialogRef.disabled = false
      }
    },

  },




}
</script>

<style lang="scss" scoped>
.btn {
  margin-bottom: 10px;
}
</style>
